<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <div class="row">
          <div class="col-lg-12">
            <el-alert :closable="false" class="mb-3" title="💡 Info" type="success">
              <p><b>Commerce Bot Messages</b> setting is a place where you can set what message to reply to your customer. By default, we have 2 predefined thread.</p>
              <ol>
                <li>Thread <b>Payment</b>. This is the first message when submit your customer orders.</li>
                <li>Thread <b>Invoice</b>. This message will be send when your customer complete the payment. You can use this message to inform your customer know that their payment has been success.</li>
              </ol>
              This is our predefined variable you can used:<br/>
              - Use <code style="font-size: 12px" v-html="'{{name}}'"></code> to be replace with <b>customer name</b><br>
              - Use <code style="font-size: 12px" v-html="'{{product}}'"></code> to be replace with <b>product details</b><br>
              - Use <code style="font-size: 12px" v-html="'{{payment}}'"></code> to be replace with <b>payment details</b><br>
              - Use <code style="font-size: 12px" v-html="'{{order_no}}'"></code> to be replace with <b>Order ID</b><br>
              - Use <code style="font-size: 12px" v-html="'{{invoice_no}}'"></code> to be replace with <b>Invoice Number</b>
            </el-alert>
            <el-form label-width="130px" label-position="left">
              <el-form-item v-for="(q, index) in messages" :key="index">
                <span class="font-weight-bold" slot="label">Thread {{ upperCaseFirst(q.topic) }}</span>
                <el-input rows="5" type="textarea" v-model="q.text"></el-input>
              </el-form-item>
            </el-form>
            <div align="right">
              <el-button @click="updateCommerceBot" :loading="loading.update_bot" size="small"
                class="btn-primary" align="right">Update</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import popupErrorMessages from '@/library/popup-error-messages';
import commerceSettingApi from '../../../api/commerceSetting';

export default {
  name: 'CommerceSetting',
  metaInfo() {
    return {
      title: 'Commerce Bot Messages Setting',
    };
  },
  data() {
    return {
      loading: {
        setting: true,
        update_bot: false,
      },
      loaderStack: 0,
      loader: null,
      messages: [],
      work_hour: {
        enabled: false,
        config: [],
        message: '',
      },
      search: '',
      temp_buttons_form: [],
      temp_list_form: [],
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    const temp = [];
    /* eslint-disable no-plusplus */
    for (let i = 0; i < 7; i++) {
      temp.push({
        day: i,
        day_name: moment().weekday(i).format('dddd'),
        from: '08:00',
        until: '17:00',
        model: [moment('08:00', 'hh:mm'), moment('17:00', 'hh:mm')],
        enabled: false,
      });
    }
    this.loadData();
  },
  methods: {
    upperCaseFirst(string) {
      return upperFirst(string);
    },
    async loadData() {
      this.showLoader();
      const response = await commerceSettingApi.get_messages({ id: this.activeWorkspace._id });
      this.hideLoader();
      this.loading.setting = false;
      await popupErrorMessages(response);
      if (response.data.messages_content) {
        this.messages = response.data.messages_content;
      } else {
        this.messages = [
          {
            topic: 'payment',
            text: 'Hai {{name}},\n\nPemesananmu sudah kami buat. Lakukan pembayaran untuk detail pemesanan dengan Order ID #{{order_no}}:\n{{product}}\n{{payment}}\n\nSegera selesaikan transaksimu, agar pemesananmu bisa diproses!',
          },
          {
            topic: 'invoice',
            text: 'Pembayaran untuk Order ID #{{order_no}} sudah terkonfirmasi. Berikut detail pemesanan kamu:\nNo Invoice : #{{invoice_no}}\n{{product}}\n{{payment}}\n\nTerimakasih sudah melakukan pemesanan.',
          },
        ];
      }
    },
    async updateCommerceBot() {
      this.showLoader();
      const response = await commerceSettingApi.update_messages({ id: this.activeWorkspace._id, data: { content: this.messages } });
      this.hideLoader();
      this.loading.update_bot = false;
      await popupErrorMessages(response);
      this.$message({
        type: 'success',
        message: this.$t('settings.success.edit'),
      });
      this.loadData();
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
  },
};
</script>
<style>
.el-switch__label.is-active {
  color: #10b759 !important;
}
.el-date-editor .el-range-separator {
  width: 15%;
}
.el-switch__label {
  color: #BDC3C7;
}
</style>
